import Hero from "./hero/hero.jsx";
import Bids from "./bids/bids.jsx";
import Top_collection from "./collectrions/top_collection.jsx";
import Tranding_category from "./categories/tranding_categories.jsx";
import HeadLine from "./headLine.jsx";
import NewseLatter from "./blog/newseLatter.jsx";
import Auctions_categories from "./categories/Auctions_categories.jsx";
import Feature_collections from "./collectrions/Feature_collections.jsx";
import Partners from "./partners/Partners.jsx";
import Browse_category from "./categories/Browse_category.jsx";
import Hero_2 from "./hero/hero_2.jsx";
import Hero_3 from "./hero/hero_3.jsx";
import Hero_4 from "./hero/hero_4.jsx";
import Hero_5 from "./hero/hero_5.jsx";
import Hero_6 from "./hero/hero_6.jsx";
import Hero_7 from "./hero/hero_7.jsx";
import Hero_new from "./hero/Hero_new.jsx";
import ItemsTabs from "./tabs/Tabs.jsx";

export {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  HeadLine,
  NewseLatter,
  Auctions_categories,
  Feature_collections,
  Partners,
  Browse_category,
  Hero_2,
  Hero_3,
  Hero_4,
  Hero_5,
  Hero_6,
  Hero_7,
  Hero_new,
  ItemsTabs,
};
