// Meta.jsx
import Head from "next/head";
import Script from "next/script";

const Meta = ({ title, keyword, desc, image, url, scripts = [] }) => {
  // Ensure image URL is absolute
  const ensureAbsoluteUrl = (imageUrl) => {
    if (!imageUrl) return "/images/experiences/1-Avatar.png";
    if (imageUrl.startsWith("http")) return imageUrl;

    // Default to current domain if just a path is provided
    const domain = process.env.NEXT_PUBLIC_DOMAIN || "mtion.xyz";
    const protocol = process.env.NODE_ENV === "development" ? "http:" : "https:";
    return `${protocol}//${domain}${imageUrl.startsWith("/") ? "" : "/"}${imageUrl}`;
  };

  const fullImageUrl = ensureAbsoluteUrl(image);

  return (
    <>
      <Head>
        <title>{title} || Create, Play, Remix. Redefine Play with mtion</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={fullImageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="mtion.xyz" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={fullImageUrl} />
        <meta name="twitter:site" content="@mtionapp" />
        <meta name="twitter:creator" content="@mtionapp" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
        <meta name="format-detection" content="telephone=no" />

        {scripts.map((script, index) => (
          <Script key={index} {...(script.id ? { id: script.id } : {})} {...(script.src ? { src: script.src } : {})} {...(script.strategy ? { strategy: script.strategy } : { strategy: "lazyOnload" })} {...(script.defer ? { defer: true } : {})}>
            {script.content && `${script.content}`}
          </Script>
        ))}
      </Head>
    </>
  );
};

Meta.defaultProps = {
  title: "mtion.xyz | Create, Play, Remix. Redefine Play with mtion",
  keyword: "create, play, remix, UGC platform, interactive experiences, social gaming, game creation, immersive worlds, storytelling, mtion, player-generated content, creative expression",
  desc: "Redefine play, shape new worlds, and unleash your story. mtion empowers anyone to build interactive experiences and share their creativity.",
  image: "/images/experiences/1-Avatar.png",
  url: "https://www.mtion.xyz",
};

export default Meta;
